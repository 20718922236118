import React, { useEffect, Fragment, useContext } from "react";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
    PlusIcon,
    ChevronDownIcon,
    XMarkIcon,
    NoSymbolIcon,
} from "@heroicons/react/24/outline";
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Table from "../../components/Table";
import InlineCheckbox from "../../components/InlineCheckbox";
import SelectInput from "../../components/SelectInput";
import DateInput from "../../components/DateInput";
import RadioButtons from "../../components/RadioButtons";
import Tag from "../../components/Tag";
import { Dialog, Popover, Transition } from "@headlessui/react";

const columns = [
    {
        name: "name",
        displayText: "Nombre y Apellido",
        clickable: true,
        truncate: true,
    },
    { name: "govId", displayText: "C.I." },
    { name: "age", displayText: "Edad" },
    { name: "sex", displayText: "Sexo" },
    { name: "city", displayText: "Ciudad    " },
    { name: "lastConsultationDate", displayText: "Última visita" },
];

const sex = ["Masculino", "Femenino"];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function PatientPage() {
    const [patientsError, setPatientsError] = useState("");
    const [patients, setPatients] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [search, setSearch] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [cities, setCities] = useState([]);

    // getting initial data
    useEffect(() => {
        api.cities.getMulti().then((response) => {
            setCities(response);
            setLoading(false);
            // api.patients.getMulti('modification', search, null, null, null).then((resp) => (setPatients(resp.results.filter((array) => { return array.firstName ? true : false }).map((element) => {
            //     return ({ ...element, name: element.firstName + ' ' + element.lastName, city: response.find((city) => city.id === element.city)?.name, sex: element.sex === 1 ? 'Masculino' : 'Femenino' })
            // })), setTotal(resp.count), setLoading(false)))
        });
    }, []);

    useEffect(() => {
        if (search) {
            setLoading(true);
            api.patients.getMulti("alphabetic", search, null, null, null).then(
                (response) => (
                    setPatients(
                        response.results
                            .filter((array) => {
                                return array.firstName ? true : false;
                            })
                            .map((element) => {
                                return {
                                    ...element,
                                    name:
                                        element.firstName +
                                        " " +
                                        element.lastName,
                                    city: cities.find(
                                        (city) => city.id === element.city
                                    )?.name,
                                    sex:
                                        element.sex === 1
                                            ? "Masculino"
                                            : "Femenino",
                                };
                            })
                    ),
                    setTotal(response.count),
                    setLoading(false)
                )
            );
        } else if (search === "") {
            setPatients([]);
        }
    }, [search]);

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar
                currentScreen={"Pacientes"}
                sidebarOpen={sidebarOpen}
                setSidebarOpen={setSidebarOpen}
            />
            <div className="md:pl-64 flex flex-col h-screen flex-1">
                {loading ? (
                    <div className="flex h-full items-center justify-center ">
                        <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                    </div>
                ) : (
                    <>
                        <div className="bg-gray-100 z-10">
                            <Header
                                value={search}
                                search={true}
                                setSearch={setSearch}
                                setSidebarOpen={setSidebarOpen}
                                searchPlaceholder="Buscar por nombre o número de documento."
                                buttonText={
                                    patients.length ? "Nuevo paciente" : null
                                }
                                href="/patients/new"
                            />
                            {patientsError && (
                                <div className="mb-3 mx-10 text-sm text-red-600">
                                    {patientsError}
                                </div>
                            )}
                        </div>
                        <main className="flex-1 bg-gray-100 overflow-y-auto">
                            {patients.length ? (
                                <div className="pt-4 pb-10">
                                    <div className="max-w-7xl mx-auto ml-2 px-4 mb-4 sm:px-6 md:px-8">
                                        <h1 className="text-2xl font-bold leading-9 text-gray-600">
                                            Total de pacientes registrados:{" "}
                                            {total}
                                        </h1>
                                    </div>
                                    <div className="px-4 sm:px-6 md:px-8">
                                        <Table
                                            module="patients"
                                            actiontext="Ver"
                                            columns={columns}
                                            rows={patients}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div className="flex h-full">
                                    <div className="mx-auto my-auto text-center">
                                        <NoSymbolIcon
                                            className="h-12 w-12 mx-auto text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                                            No se encontraron resultados
                                        </h3>
                                        <p className="mt-1 text-sm font-semibold text-gray-500">
                                            Probá con otros datos o registrá un
                                            nuevo paciente.
                                        </p>
                                        <div className="mt-6">
                                            <Link
                                                to="/patients/new"
                                                type="button"
                                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-semibold rounded-md text-white bg-primary hover:bg-primary"
                                            >
                                                <PlusIcon
                                                    className="-ml-1 mr-2 h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                                Nuevo paciente
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </main>
                    </>
                )}
            </div>
        </div>
    );
}
