import React, { useEffect } from "react";
import { useState } from 'react'
import {
    CalendarIcon,
    ChartBarIcon,
    EyeIcon,
    MapPinIcon,
    Bars3Icon,
    BuildingOfficeIcon,
    UserCircleIcon,
    UserGroupIcon,
} from '@heroicons/react/24/outline'
import api from "../../api";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import SelectInput from "../../components/SelectInput";
import InlineCheckbox from "../../components/InlineCheckbox";
import RadioButtons from "../../components/RadioButtons";
import FileInput from "../../components/FileInput";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const radio = [
    { id: "male", default: true, label: "Masculino", value: 1 },
    { id: "female", default: false, label: "Femenino", value: 2 }
]

const roles = [
    { id: 'lic', default: true, label: 'Lic.', value: 5 },
    { id: "resident", default: false, label: "Dr. Residente", value: 1 },
    { id: "senior", default: false, label: "Dr. Senior", value: 3 },
    { id: "secretary", default: false, label: "Secretaría", value: 2 },
    { id: "admin", default: false, label: "Administrador", value: 4 },
    { id: "bachelor", default: false, label: "Administrador", value: 5 },
]

export default function UserNewPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const { idUser } = useParams()
    const [specialties, setSpecialties] = useState([]);
    const [user, setUser] = useState({
        sex: '1',
        role: '5',
    });
    const [error, setError] = useState({});

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));
    const handleNumber = (field, value) => setUser((prev) => ({ ...prev, [field]: value.replace(/\D/g, '') }));

    const handleSubmit = (event) => {
        event.preventDefault()
        if (user.role === '4' || user.role === '2') {
            delete user.digitalSignature
            delete user.specialty
            delete user.registrationNumber
        } else if (user.registrationNumber) {
            handleChange('registrationNumber', parseInt(user.registrationNumber))
        }
        if (user.role === '1' || user.role === '3' || user.role === '5' || user.role === 1 || user.role === 3 || user.role === 5) {
            let formData = new FormData();
            for (var key in user) {
                if (user[key] && (key !== 'digitalSignature' || typeof user[key] === 'object')) {
                    formData.append(key, user[key]);
                }
            }
            if (idUser) {
                api.users.update(idUser, formData).then((response) => navigate('/users')).catch((error) => { setError(error.message); setLoading(false); })
            } else {
                api.users.create(formData).then(() => navigate('/users')).catch(response => {
                    setError(response.message)
                }
                )
            }
        } else {
            if (idUser) {
                api.users.update(idUser, user).then((response) => navigate('/users')).catch((error) => { setError(error.message); setLoading(false); })
            } else {
                api.users.create(user).then((response) => navigate('/users')).catch(error => {
                    setError(error.message)
                }
                )
            }
        }
    };

    useEffect(() => {
        api.specialties.getMulti().then((response) => {
            setSpecialties(response)
        })
        if (idUser) {
            api.users.getOne(idUser).then((response) => {
                console.log(response)
                setUser({ ...response, sex: response.sex.toString(), role: response.role.toString() })
            })
        }
    }, [])

    useEffect(() => {
        if (user.role === '4' || user.role === '2') {
            delete user.digitalSignature
            delete user.specialty
            delete user.registrationNumber
        }
        if (user.role === '1' || user.role === '3' || user.role === '5') {
            handleChange('specialty', '1')
        }
    }, [user.role])

    const pages = [
        { name: 'Usuarios', href: '/users', current: false },
        { name: idUser ? 'Detalle' : 'Nuevo usuario', href: `#`, current: true },
    ]

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Usuarios'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1 bg-gray-100 pb-6">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <div className="flex">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <div className="flex-col flex-1 mt-12 divide-y divide-gray-200">
                            <div className="max-w-7xl grid grid-cols-2 gap-8 px-48">
                                <TextInput showErrorMessage={true} required={true} containerClassName='w-full' errorMessage={error?.firstName} value={user?.firstName} handleChange={handleChange} name="firstName" label="Nombres" />
                                <TextInput showErrorMessage={true} required={true} containerClassName='w-full' errorMessage={error?.lastName} value={user?.lastName} handleChange={handleChange} name="lastName" label="Apellidos" />
                                <TextInput showErrorMessage={true} required={true} containerClassName='w-full' errorMessage={error?.email} value={user?.email} handleChange={handleChange} name="email" label="Correo Electrónico" />
                                <TextInput showErrorMessage={true} required={true} containerClassName='w-full' errorMessage={error?.govId} value={user?.govId} handleChange={handleChange} name="govId" label="Cédula de identidad" />
                                <TextInput showErrorMessage={true} required={true} containerClassName='w-full' errorMessage={error.cellphone} handleChange={handleNumber} value={user.cellphone} name="cellphone" label="Celular" />
                                <RadioButtons defaultValue={user?.sex} value={user?.sex} handleChange={handleChange} name="sex" label="Sexo" options={radio} />
                                <div className="col-span-2">
                                    <RadioButtons optionsContainerStyle='space-y-4 sm:flex sm:items-center sm:space-y-0 sm:gap-x-24' defaultValue={user?.role} value={user?.role} handleChange={handleChange} name='role' label='Rol' options={roles} />
                                </div>
                                {(user?.role === '1' || user?.role === '3' || user?.role === '5') &&
                                    <div className="mx-auto col-span-2 w-1/2 gap-4 flex flex-col">
                                        <TextInput showErrorMessage={true} errorMessage={error.registrationNumber} handleChange={handleNumber} value={user?.registrationNumber} name="registrationNumber" label="Reg. Prof. Nro" />
                                        <SelectInput errorMessage={error?.specialty} value={user?.specialty} handleChange={handleChange} name="specialty" label="Especialidad" className="shadow-sm pl-2 w-full bg-white border border-gray-300 focus:ring-primary focus:border-primary block sm:text-sm rounded-md" options={specialties.map((element) => (<option value={element.id} label={element.name}>{element.name}</option>))} />
                                        <FileInput label='Agregar Firma' buttonText='Adjuntar Firma' handleChange={handleChange} name='digitalSignature' value={user?.digitalSignature} />
                                    </div>
                                }
                                <button type="submit" className="col-span-2 w-1/2 mx-auto flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                    Guardar usuario
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}
