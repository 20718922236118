import React, { useEffect } from "react";
import { useState } from 'react'
import {
    Bars3Icon,
} from '@heroicons/react/24/outline'
import api from "../../api";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Breadcrumbs from "../../components/Breadcrumbs";
import TextInput from "../../components/TextInput";
import FileInput from "../../components/FileInput";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const radio = [
    { id: "male", default: true, label: "Masculino", value: 1 },
    { id: "female", default: false, label: "Femenino", value: 2 }
]

const roles = [
    { id: "resident", default: true, label: "Dr. Residente", value: 1 },
    { id: "senior", default: false, label: "Dr. Senior", value: 3 },
    { id: "secretary", default: false, label: "Secretaría", value: 2 },
    { id: "admin", default: false, label: "Administrador", value: 4 },
    { id: "bachelor", default: false, label: "Licenciado", value: 5 },
]

export default function ProfilePage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState({
        sex: 1,
        role: 1,
    });
    const [error, setError] = useState({});
    const [idUser, setIdUser] = useState(JSON.parse(localStorage.getItem('BDOuserData'))?.id)

    const handleChange = (field, value) => setUser((prev) => ({ ...prev, [field]: value }));

    const handleSubmit = (event) => {
        event.preventDefault()
        setSuccess(false);
        let error = false
        if (user.role === '4' || user.role === '2') {
            delete user.digitalSignature
            delete user.specialty
            delete user.registrationNumber
        }
        if (user.role === '1' || user.role === '3' || user.role === 1 || user.role === 3) {
            let formData = new FormData();
            for (var key in user) {
                if (user[key] && (key !== 'digitalSignature' || typeof user[key] === 'object')) {
                    formData.append(key, user[key]);
                }
            }
            api.users.update(idUser, formData).then((response) => { setSuccess(true) }).catch((error) => setError(error.message))
        } else {
            api.users.update(idUser, user).then((response) => { setSuccess(true) }).catch((error) => setError(error.message))
        }
    };

    useEffect(() => {
        if (idUser) {
            api.users.getMe().then((response) => {
                setUser({ ...response, role: response.role.toString() })
            })
        }
    }, [])

    useEffect(() => {
        if (user.role === '4' || user.role === '2') {
            delete user.digitalSignature
            delete user.specialty
            delete user.registrationNumber
        }
    }, [user.role])

    const pages = [
        { name: 'Perfil', href: '#', current: false },
        { name: 'Editar', href: '#', current: true },
    ]

    return (
        <div className="bg-gray-100 h-screen">
            <Sidebar currentScreen={'Perfil'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <div className="md:pl-64 flex flex-col flex-1">
                <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                        onClick={() => setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="flex flex-col flex-1 ml-6 mt-6">
                    <form onSubmit={(event) => handleSubmit(event)}>
                        <div className="flex">
                            <Breadcrumbs pages={pages} />
                        </div>
                        <div className="flex-col flex-1 mt-12 px-2">
                            <p className="text-[#111827] font-medium text-lg">Información del usuario</p>
                            <div className="border border-[#E5E7EB] my-4 w-3/4" />
                            <div className="max-w-7xl grid grid-cols-2 gap-8 w-2/3">
                                <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error?.firstName} value={user?.firstName} handleChange={handleChange} name="firstName" label="Nombres" />
                                <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error?.lastName} value={user?.lastName} handleChange={handleChange} name="lastName" label="Apellidos" />
                                <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error?.email} value={user?.email} handleChange={handleChange} name="email" label="Correo Electrónico" />
                                <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error?.govId} value={user?.govId} handleChange={handleChange} name="govId" label="Cédula de identidad" />
                                <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error.cellphone} handleChange={handleChange} value={user.cellphone} name="cellphone" label="Celular" />
                                {(user?.role === '1' || user?.role === '3') && <TextInput showErrorMessage={true} containerClassName='w-full' errorMessage={error.registrationNumber} handleChange={handleChange} value={user.registrationNumber} name="registrationNumber" label="Reg. Prof. Nro" />}
                                {(user?.role === '1' || user?.role === '3') && <FileInput label='Firma' buttonText='Adjuntar Firma' handleChange={handleChange} name='digitalSignature' value={user?.digitalSignature} />}
                                {((user?.role === '1' || user?.role === '3') && user?.digitalSignature) && <img alt='signature' src={typeof user.digitalSignature === 'string' ? process.env.REACT_APP_API_BASE_URL + user?.digitalSignature : URL.createObjectURL(user?.digitalSignature)} height={128} width={128} />}
                                <button type="submit" className="col-span-2 w-1/2 mx-auto mt-6 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                    Guardar cambios
                                </button>
                            </div>
                            {success &&
                                <div className="bg-white border border-gray-300 mt-4 w-fit py-2 px-4 rounded-xl">
                                    Sus cambios han sido guardados exitosamente.
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div >
        </div >
    );
}
