import React, { useContext, useEffect } from "react";
import { Fragment, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { Menu, Dialog, Transition } from '@headlessui/react'
import Modal from "../../components/Modal";
import { Link } from "react-router-dom";
import {
    Bars3Icon,
} from '@heroicons/react/24/outline'
import { SearchIcon, MapPinIcon as SolidMapPinIcon, CakeIcon, BuildingOfficeIcon as SolidBuildingOfficeIcon, ChevronDownIcon } from '@heroicons/react/24/solid'
import api from "../../api";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import InlineCheckbox from "../../components/InlineCheckbox";
import DateInput from "../../components/DateInput";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import CustomCombobox from "../../components/CustomCombobox";
import Badge from "../../components/Badge";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
);

const options = [
    { name: 'Datos generales', href: '#' },
    { name: 'Cirugías', href: '/reports/surgery-report' },
    { name: 'Estudios', href: '/reports/study-report' },
    { name: 'Medicamentos', href: '/reports/medication-report' },
]

const columns = [
    { name: 'name', displayText: 'Nombre y Apellido', truncate: true },
    { name: 'govId', displayText: 'C.I.' },
    { name: 'age', displayText: 'Edad' },
    { name: 'sex', displayText: 'Sexo' },
    { name: 'city', displayText: 'Ciudad    ' },
    { name: 'lastConsultation', displayText: 'Última visita' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function GeneralDataPage() {

    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(undefined);
    const [diagnoses, setDiagnoses] = useState(undefined);
    const [doctors, setDoctors] = useState(undefined);
    const [page, setPage] = useState('Datos generales');
    const [dataBar, setDataBar] = useState(undefined)
    const [dataPie, setDataPie] = useState(undefined)
    const [filters, setFilters] = useState({})
    const [fromDate, setFromDate] = useState(undefined)
    const [toDate, setToDate] = useState(undefined)
    const [diagnosesFilter, setDiagnosesFilter] = useState(undefined)
    const [doctorFilter, setDoctorFilter] = useState(undefined)
    const [consultationTypeFilter, setConsultationTypeFilter] = useState(undefined)

    const handleFromDate = (field, value) => setFromDate(value);
    const handleToDate = (field, value) => setToDate(value);
    const handleDiagnosesFilter = (field, value) => setDiagnosesFilter(value);
    const handleDoctorFilter = (field, value) => setDoctorFilter(value);
    const handleConsultationTypeFilter = (field, value) => {
        if (value) {
            let aux = consultationTypeFilter ? [...consultationTypeFilter] : []
            aux.push(field)
            setConsultationTypeFilter(aux)
        } else {
            let aux = consultationTypeFilter.filter((element) => field !== element)
            setConsultationTypeFilter(aux)
        }
    };

    useEffect(() => {
        api.diagnostics.getMulti().then((response) => setDiagnoses(response))
        api.users.getDoctors().then((response) => {
            setDoctors(response.map((element) => {
                const isLicenciado = element.role.toLowerCase() === "licenciado";
                const prefix = isLicenciado 
                    ? "Lic."
                    : element.sex.toLowerCase() === "masculino"
                        ? "Dr."
                        : "Dra.";
                return {
                    name: `${prefix} ${element.firstName} ${element.lastName}`,
                    ...element
                };
            }));
        });
    }, [])

    useEffect(() => {
        setLoading(true)
        console.log(filters)
        if (filters) {
            let aux = new URLSearchParams();
            for (var prop in filters) {
                if (Object.prototype.hasOwnProperty.call(filters, prop)) {
                    if (Array.isArray(filters[prop])) {
                        filters[prop].forEach((element) => aux.append(prop, element.id ? element.id : element))
                    } else {
                        aux.append(prop, filters[prop])
                    }
                }
            }
            api.generalData.getReports(aux).then((response) => { setData(response); })
        } else {
            api.generalData.getReports().then((response) => { setData(response); });
        }
    }, [filters])

    const saveFromDate = () => {
        if (fromDate)
            setFilters((prev) => ({ ...prev, date_after: fromDate }))
    }

    const saveToDate = () => {
        if (toDate)
            setFilters((prev) => ({ ...prev, date_before: toDate }))
    }

    const saveDiagnosesFilter = () => {
        if (diagnosesFilter)
            setFilters((prev) => ({ ...prev, diagnostics: diagnosesFilter }))
    }

    const saveDoctorFilter = () => {
        if (doctorFilter)
            setFilters((prev) => ({ ...prev, doctor: doctorFilter }))
    }

    const saveConsultationTypeFilter = () => {
        if (consultationTypeFilter)
            setFilters((prev) => ({ ...prev, consultation_type: consultationTypeFilter }))
    }

    const deleteFromDate = () => {
        let state = { ...filters }
        delete state.date_after
        setFilters(state)
        setFromDate(undefined)
    }

    const deleteToDate = () => {
        let state = { ...filters }
        delete state.date_before
        setFilters(state)
        setToDate(undefined)
    }

    const deleteDates = () => {
        let state = { ...filters }
        delete state.date_after
        delete state.date_before
        setFilters(state)
        setFromDate(undefined)
        setToDate(undefined)
    }

    const deleteDiagnosesFilter = (value) => {
        setFilters((prev) => ({ ...prev, diagnoses: filters.diagnoses.filter((element) => element.id !== value) }))
        setDiagnosesFilter(diagnosesFilter.filter((element) => element.id !== value))
    }

    const deleteAllDiagnosesFilter = () => {
        let state = { ...filters }
        delete state.diagnostics
        setFilters(state)
        setDiagnosesFilter([])
    }

    const deleteDoctorFilter = (value) => {
        setFilters((prev) => ({ ...prev, doctor: filters.doctor.filter((element) => element.id !== value) }))
        setDoctorFilter(doctorFilter.filter((element) => element.id !== value))
    }

    const deleteAllDoctorFilter = () => {
        let state = { ...filters }
        delete state.doctor
        setFilters(state)
        setDoctorFilter([])
    }

    const deleteConsultationTypeFilter = (value) => {
        setFilters((prev) => ({ ...prev, consultationType: filters.consultationType.filter((element) => element.id !== value) }))
        setConsultationTypeFilter(consultationTypeFilter.filter((element) => element.id !== value))
    }

    const deleteAllConsultationTypeFilter = () => {
        let state = { ...filters }
        delete state.consultationType
        setFilters(state)
        setConsultationTypeFilter([])
    }

    useEffect(() => {
        if (data) {
            setDataBar({
                labels: data.charts.bar.data.map((element) => element.date),
                datasets: [{
                    label: 'Nuevos registrados',
                    data: data.charts.bar.data.map((element) => element.newPatients),
                    backgroundColor: '#87BBD9',
                    stack: 'Stack 0',
                },
                {
                    label: 'Pacientes que consultaron',
                    data: data.charts.bar.data.map((element) => element.patientsAttended),
                    backgroundColor: '#F150FF',
                    stack: 'Stack 1',
                },
                {
                    label: 'Cantidad de consultas',
                    data: data.charts.bar.data.map((element) => element.totalConsultations),
                    backgroundColor: '#EF5353',
                    stack: 'Stack 2',
                }
                ]
            })
            setDataPie({
                labels: [`Hombres: ${Math.round(data.charts.pie.men / (data.charts.pie.men + data.charts.pie.women) * 100)}%`, `Mujeres: ${Math.round(data.charts.pie.women / (data.charts.pie.men + data.charts.pie.women) * 100)}%`],
                datasets: [
                    {
                        data: [data.charts.pie.men, data.charts.pie.women],
                        backgroundColor: ['#7A9FFF', '#FF6969'],
                    }
                ]
            })
        }
    }, [data])

    useEffect(() => {
        if (dataBar)
            setLoading(false)
    }, [dataBar])

    const optionsBar = {
        plugins: {
            title: {
                display: false,
                text: '',
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        maintainAspectRatio: false,
    };

    const optionsPie = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Pacientes según sexo'
            }
        }
    };

    return (
        <div className="bg-gray-50 h-screen">
            <Sidebar currentScreen={'Reportes'} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {loading ?
                <div className="flex h-full items-center justify-center ">
                    <div className="w-16 h-16 border-b-2 border-blue-900 rounded-full animate-spin"></div>
                </div> :
                <div className="md:pl-64 flex flex-col flex-1">
                    <main className="flex-1 bg-white">
                        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
                            <button
                                type="button"
                                className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                                onClick={() => setSidebarOpen(true)}
                            >
                                <span className="sr-only">Open sidebar</span>
                                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="flex flex-1 pb-4 ml-6 mt-5">
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-sm font-semibold text-white shadow-sm ring-0 outline-0">
                                        Datos generales
                                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-white" aria-hidden="true" />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            {options.map((element) => (
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href={element.href}
                                                            className={classNames(
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'
                                                            )}
                                                        >
                                                            {element.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                            <div className="h-10 border border-[#E5E7EB] mx-8 " />
                            <div className="flex gap-6">
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Fecha
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <DateInput handleChange={handleFromDate} name='fromDate' label='Desde' />
                                                <DateInput handleChange={handleToDate} name='toDate' label='Hasta' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteDates()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => (saveToDate(), saveFromDate())} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Diagnóstico
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <CustomCombobox value={diagnosesFilter} multiple={true} required={true} options={diagnoses} handleChange={handleDiagnosesFilter} name='diagnoses' label='Diagnóstico' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteAllDiagnosesFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => saveDiagnosesFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Doctor
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <CustomCombobox multiple={true} required={true} options={doctors} handleChange={handleDoctorFilter} name='doctors' label='Doctor' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteAllDoctorFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => saveDoctorFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm text-black ring-0 outline-0">
                                            Tipo de consulta
                                            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-4 flex flex-col gap-3 px-5">
                                                <p>General</p>
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={1} label='Control por tratamiento' />
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={2} label='Control post quirúrgico' />
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={3} label='Estudios' />
                                                <p>De especialidad</p>
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={4} label='Control por tratamiento' />
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={5} label='Control post quirúrgico' />
                                                <InlineCheckbox handleChange={handleConsultationTypeFilter} name={6} label='Estudios' />
                                                <div className="flex gap-3">
                                                    <button onClick={() => deleteAllConsultationTypeFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-[#D1D5DB] rounded-md shadow-sm text-sm font-medium bg-white">
                                                        Borrar filtros
                                                    </button>
                                                    <button onClick={() => saveConsultationTypeFilter()} className="flex justify-center gap-2 py-2 px-2 w-full border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary">
                                                        Aplicar filtro
                                                    </button>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </main>
                    <main className="bg-gray-50 px-7">
                        <div className="flex flex-wrap mt-4 gap-3">
                            {filters.date_after && <Badge text={`Desde: ${filters.date_after}`} handleDelete={() => deleteFromDate()} />}
                            {filters.date_before && <Badge text={`Hasta: ${filters.date_before}`} handleDelete={() => deleteToDate()} />}
                            {filters.diagnostics && filters.diagnostics.map((element) => (<Badge text={element.name} handleDelete={() => deleteDiagnosesFilter(element.id)} />))}
                            {filters.doctor && filters.doctor.map((element) => (<Badge text={element.name} handleDelete={() => deleteDoctorFilter(element.id)} />))}
                        </div>
                        <div className="flex mt-4 gap-6 w-full">
                            {dataBar && <div className="w-2/3 bg-white p-5 rounded-lg border"><Bar options={optionsBar} data={dataBar} /></div>}
                            {dataPie && <div className="bg-white p-5 rounded-lg border"><Pie options={optionsPie} data={dataPie} /></div>}
                        </div>
                        <p className="text-2xl font-bold mt-4">Información</p>
                        <div className="grid grid-cols-3 text-white w-1/2 gap-y-6 my-4">
                            <div className="bg-[#00738C] rounded-l-lg shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Pacientes atendidos</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.patientsAttended}</p>
                            </div>
                            <div className="bg-primary shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Pacientes nuevos</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.newPatients}</p>
                            </div>
                            <div className="bg-[#FF7992] rounded-r-lg shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Total de consultas</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.totalConsultations}</p>
                            </div>
                            <div className="bg-[#3F325B] rounded-l-lg shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Pacientes con DM</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.dmPatients}</p>
                            </div>
                            <div className="bg-[#683C69] shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Pacientes con HTA</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.htaPatients}</p>
                            </div>
                            <div className="bg-[#A34F7C] rounded-r-lg shadow-sm py-5 px-4 h-36">
                                <p className="font-semibold leading-6">Pacientes con DM y HTA</p>
                                <p className="text-2xl font-semibold leading-8">{data?.summaryData.dmAndHtaPatients}</p>
                            </div>
                        </div>
                    </main>
                </div >
            }
        </div >
    );
}
